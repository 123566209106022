<template>
  <div class="test">{{blub}}

      <button @click="counter"></button>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {

    setup(){
        const blub= ref(0)
        function counter(){
            blub.value++;
        }
        counter();
        return {blub,counter}
    }

}
</script>

<style>

.test{
    z-index: 2000;
    position: absolute;

}
</style>